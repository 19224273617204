.servicesSection {
    width: 100%;
    padding: 30px 0 60px 0;
    background: #f4f7fb;
}

.wrapperServices {
    display: flex;
    gap: 15px;
    width: 100%;
}

.subTitle {
    color: #003B56;
    font-weight: 600;
    font-size: 20px; 
    position: relative;
    display: inline-block;
    padding-right: 110px;
    margin-bottom: 48px;
    white-space: nowrap;
}

.subTitle::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-80%);
    width: 100px;
    height: 5px;
    background-color: rgba(0, 59, 86, 0.6);
    display: inline-block;
    border-radius: 5px;
}

.descriptionWrapper {
    max-width: 400px
}

.description {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 700px;
    overflow-y: hidden;
}

.description p:first-child {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.description p {
    color: #003B56; 
}

.wrapperBlocks {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
}

.twoServiceBlock {
    display: flex;
    gap: 40px;
    width: 100%;
    justify-content: center;
}

.serviceBlock {
    background: #F4F4F3;
    border: 5px solid #003B56;
    box-shadow: 5px 5px 5px rgba(0, 59, 86, 0.5);
    border-radius: 10px;
    max-width: 345px;
    min-width: 280px;
    height: 204px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
}

.serviceTitle {
    color: #002b3e;
    font-weight: 700;
    font-size: 18px; 
    margin-bottom: 5px;
}

.serviceDescription {
    color: #003B56; 
    margin-bottom: 5px;
    font-size: 15px;
}

.wrapperLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.serviceLink {
    background-color: transparent;
    border: none;
    border: 1px solid #f58020;
    color: #f58020;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700; 
    border-radius: 5px;
    text-align: center;
    padding: 12px 20px;
    display: block;
    position: relative;
    overflow: hidden;
}

.serviceLink::before {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f58020; 
    transition: transform 0.3s ease-in-out;
    z-index: -1;
}

.serviceLink:hover {
    color: #FFFFFF;
}

.serviceLink:hover::before {
    transform: translateX(100%);
}

.wrapperCarImg {
    max-width: 80px;
}

@media screen and (max-width: 1024px) {
    .wrapperServices {
        flex-direction: column;
        gap: 45px;
    }

    .descriptionWrapper {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .subTitle {
        padding-right: 0;
    }

    .subTitle::after {
        bottom: 0;
        left: 50%;
        top: 115%;
        transform: translateX(-50%);
    }

    .description p {
        padding-bottom: 5px;
    }

    .twoServiceBlock {
        justify-content: center;
    }

    .description {
        max-height: initial;
        overflow-y: initial;
    }
}

@media screen and (max-width: 768px) {
    .twoServiceBlock {
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
}