.aboutCompanySection {
    width: 100%;
    padding: 80px 0 30px 0;
}

.subTitle {
    color: #F58020;
    font-weight: 700;
    font-size: 20px; 
    margin-bottom: 30px;
    text-align: center;
}

.wrapperSections {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 45px;

}

.video {
    min-width: 590px;
    height: 100%;
    border: 5px solid #F58020;
    border-radius: 35px;
}

.seccondSection {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 590px;
}

.contentAboutCompany {
    padding: 30px;
    background: #F4F7FB;
    border: 5px solid #003B56;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 418px;
    min-width: 320px;
    height: 290px;
    overflow-y: auto;
}

.contentAboutCompany p {
    color: #003B56; 
}

.contentAboutSubscibe {
    background: #F4F7FB;
    border: 5px solid #F58020;
    border-radius: 35px;
    padding: 10px 20px;
    max-width: 418px;
    min-width: 320px;
    overflow-y: auto;
}

.firstPolygon {
    transform: translateX(110px);
    display: inline-block;
}

.contentAboutSubscibe p {
    font-weight: 700;
    font-size: 30px;
    color: #021C41;
    display: flex;
    text-align: center;
    line-height: 34px;
}

.contentAboutSubscibe p:last-child {
    font-size: 16px;
    text-align: start;
    font-weight: initial;
    line-height: 25px;
    color: #003B56;
}

.contentAboutSubscibe p b {
    color: #FF0000;
}

.contentAboutSubscibe p i {
    transform: translateY(20px);
}

.wrapperLink {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
}

.linkToYouTube {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 4px 7px;
    border: 4px solid #FF0000;
    border-radius: 10px;
}

@media screen and (min-width: 1025px) {
    .contentAboutSubscibe::-webkit-scrollbar,
    .contentAboutCompany::-webkit-scrollbar {
        width: 5px;
    }
      
    .contentAboutSubscibe::-webkit-scrollbar-track,
    .contentAboutCompany::-webkit-scrollbar-track {
        background-color: #FFFFFF;
    }
      
    .contentAboutSubscibe::-webkit-scrollbar-thumb,
    .contentAboutCompany::-webkit-scrollbar-thumb {
        background-color: #F58020;
        border-radius: 10px;
    }
}

@media screen and (max-width: 1200px) {
    .contentAboutSubscibe {
        max-width: 590px;
    }

    .contentAboutSubscibe p {
        justify-content: center;
    }

    .contentAboutSubscibe p i {
        transform: translateY(10px);
    }

    .wrapperSections {
        gap: 10px;
    }
}

@media screen and (max-width: 1024px) {
    .video {
        width: 100%;
        display: flex;
        justify-content: center;

        min-width: initial;
        border: initial;
    }

    .seccondSection {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .wrapperSections {
        gap: 35px;
    }
    
    .contentAboutCompany,
    .contentAboutSubscibe {
        height: initial;
        overflow-y: initial
    }
}

@media screen and (max-width: 768px) {
    .seccondSection {
        flex-direction: column;
        align-items: center;
    }

    .aboutCompanySection {
        padding: 120px 0 30px 0;
    }
}