.reviewSection {
    background: #F4F4F3;
    width: 100%;
    padding: 30px 0;
}

.subTitle {
    margin-bottom: 26px;
    color: #003B56;
    font-size: 20px;
    font-weight: 700; 
    display: flex;
    justify-content: center;
    position: relative;
    padding-bottom: 25px;
    white-space: nowrap;
}

.subTitle::after {
    content: "";
    display: inline-block;
    background-color: #F58020;
    height: 5px;
    width: 100px;
    position: absolute;
    bottom: 0px;
}

.reviewContent {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 31px;
}

.reviewContentSecondeBlock {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.descriptionWrapper {
    max-width: 470px;
    margin-right: 10px;
}

.titleOfDescription {
    font-weight: 700;
    font-size: 20px;
    color: #F58020; 
    margin-bottom: 26px;
}

.description {
    color: #003B56;
    font-weight: 400; 
    line-height: 34px;
}

.estimate {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 230px;
}

.estimateDiscuss {
    font-weight: 700;
    font-size: 64px;
    color: var(--baseOrange);
    white-space: nowrap;
}

.wrapperStars {
    display: flex;
    gap: 17px;
}

.reviewQuantity {
    color: #003B56;
    font-weight: 700;
    font-size: 32px;
}

.presentation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 230px;
}

.percentList {
    display: flex;
    align-items: center;
    gap: 10px;
}

.percentId {
    font-size: 20px;
    color: #003B56;
    font-weight: 900;
}

.percentWrapper {
    width: 120px;
    height: 24px;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.row {
    color: #003B56;
}

.percent {
    font-weight: 400;
    font-size: 20px;
    color: #003B56;
}

.buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 33px;
    max-height: 230px;
}

.feedbackBtn {
    width: 230px;
    background: #F58020;
    border-radius: 5px;
    color: #FDFDFF;
    font-size: 18px;
    font-weight: 700; 
    padding: 12px 20px;
    transition: all .3s;
}

.feedbackBtn:hover {
    transform: translateY(-5px);
}

.feedbackBtn:active {
    transform: translateY(-1px);
}

.animationFromTransparent {
    transition: all .3s;    
    width: 180px;
    background-color: transparent;
    border: none;
    border: 1px solid #f58020;
    color: #f58020;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700; 
    border-radius: 5px;
    text-align: center;
    padding: 12px 20px;
    margin: 0 auto;
    display: block;
    position: relative;
    overflow: hidden;
}

.animationFromTransparent::before {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f58020; 
    transition: transform 0.3s ease-in-out;
    z-index: -1;
}

.animationFromTransparent:hover {
    color: #FFFFFF;
    transform: translateY(-5px);
}

.animationFromTransparent:hover::before {
    transform: translateX(100%);
}

.animationFromTransparent:active {
    transform: translateY(1px);
}

.percentActive {
    background: var(--baseOrange);
    border-radius: 5px;
    height: 100%;
    display: inline-block;
}

@media screen and (max-width: 1250px) {
    .reviewContent,
    .reviewContentSecondeBlock {
        gap: 15px;
    }

    .reviewContentSecondeBlock {

    }
}

@media screen and (max-width: 1024px) {
    .reviewContentSecondeBlock {
        flex-direction: column;
    }
    .buttons {
        align-items: end;
    }
}

@media screen and (max-width:768px) {
    .reviewContent {
        flex-direction: column;
        align-items: center;
    }

    .readmoreBtn {
        width: 230px;
    }

    .estimate {
        align-items: center;
    }

    .titleOfDescription,
    .description {
        text-align: center;
    }
}