.calculateFeeSection {
    width: 100%;
    padding: 30px 0 38px 0;
}

.subTitle {
    color: #003B56;
    font-weight: 700;
    font-size: 20px; 
    text-align: center;
    margin-bottom: 40px;
}

.wrapperCategories {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 40px;
}

.categoryCard {
    padding: 15px;
    background-color: #F4F4F3;
    border: 5px solid #003B56;
    box-shadow: 5px 5px 5px rgba(0, 59, 86, 0.35);
    border-radius: 20px;
    width: 260px;
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: center;
    height: 193px;
}

.categoryCard svg {
    min-width: 50px;
    min-height: 50px;
}

.cardText {
    color: #003B56;
    font-size: 18px;
    font-weight: 700; 
    line-height: 20px;
    margin-top: 13px;
    text-align: center;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    height: 100px;
    overflow: hidden
}

.intoFAQs {
    background-color: transparent;
    border: none;
    border: 1px solid #f58020;
    color: #f58020;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700; 
    border-radius: 5px;
    width: 140px;
    text-align: center;
    padding: 12px 20px;
    margin: 0 auto;
    display: block;
    position: relative;
    overflow: hidden;
}

.intoFAQs::before {
    content: '';
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f58020;
    transition: transform 0.3s ease-in-out;
    z-index: -1;
}

.intoFAQs:hover {
    color: #FFFFFF;
}

.intoFAQs:hover::before {
    transform: translateX(100%);
}

@media screen and (max-width: 616px) {
    .categoryCard {
        width: 100%;
    }
}