.sosialeSection {
    padding: 30px 0;
    background: #003B56;
}

.subTitle {
    color: rgba(253, 253, 255, 0.6);
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin-bottom: 31px;
    white-space: nowrap;
}

.sosialGroup {
    display: flex;
    justify-content: center;
    gap: 77px;
}

.sosial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    min-height: 200px;
}

.icons {
    display: flex;
    align-items: center;
    gap: 15px;
    height: 50px;
}

.sosialName {
    color: #FDFDFF;
    font-size: 24px;
}

.seeMore {
    color: #F58020;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 700; 
    transition: transform 0.3s ease;
    transform: translateX(0px);
}
  
.seeMore:hover {
    color: #FFA500;
    transform: translateX(10px);
}
  
.seeMore i {
    transform: translateY(2px);
}