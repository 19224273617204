.articles {
    width: 100%;
    padding: 30px 0;
}

.subTitle {
    color: #003B56;
    font-weight: 600;
    font-size: 20px; 
    position: relative;
    display: inline-block;
    padding-right: 110px;
    margin-bottom: 30px;
    white-space: nowrap;
}

.subTitle::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-80%);
    width: 100px;
    height: 5px;
    background-color: #F58020;
    display: inline-block;
    border-radius: 5px;
}

.wrapperArticle {
    display: flex;
    gap: 45px;
    justify-content: space-between;
}

.blogsSection {
    display: flex;
    align-items: center;
    width: calc(50% - 40px);
}

.newsSectionImg,
.blogsSectionImg {
    max-width: 340px;
    min-width: 340px;
    height: 330px;
    object-fit: cover;
    border-radius: 15px;
}

.newsSection {
    display: flex;
    align-items: center;
    width: calc(50% - 40px);
}

.wrapperArticleBlock {
    transform: translateX(-50%);
}

@media screen and (max-width: 1124px) {
    .blogsSection {
        max-width: initial;
    }
    .subTitle {
        color: #003B56;
        font-weight: 600;
        font-size: 20px;
        position: relative;
        display: block;
        margin-bottom: 50px;
        padding-right: 0;
        text-align: center;
    }

    .subTitle::after {
        content: "";
        position: absolute;
        right: 0;
        top: 115%;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        height: 5px;
        background-color: #F58020;
        display: inline-block;
        border-radius: 5px;
    }

    .blogsSection {
        margin-bottom: -150px;
    }

    .blogsSection,
    .newsSection {
        flex-direction: column;
    }

    .wrapperArticleBlock {
        transform: translateX(0px);
        transform: translateY(-150px);
    }
}

@media screen and (max-width: 768px) {
    .articles {
        margin-bottom: -100px;
    }
    .wrapperArticle {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media screen and (min-width: 1024px) {
    
}