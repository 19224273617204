.articleBlock {
    background: #F4F4F3;
    border: 5px solid #F58020;
    box-shadow: 4px 4px 4px rgba(0, 12, 51, 0.15);
    border-radius: 15px;
    width: 327px;
    min-height: 290px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.blog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.blogName {
    color: #F58020;
    font-weight: 500;
    font-size: 20px; 
}

.viewAll {
    color: rgba(4, 26, 68, 0.6);
    font-weight: 500;
    font-size: 20px; 
}

.description {
    color: #003B56;
    font-weight: 700;
    font-size: 20px; 
    margin-bottom: 12px;
}

.calendar {
    display: flex;
    align-items: center;
    gap: 13px;
    color: rgba(0, 59, 86, 0.6);
    font-weight: 500; 
    margin-bottom: 22px;
}

.readMore {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #F58020;
    font-weight: 700; 
    transition: all .2s;
}

.readMore:hover {
    transform: translateX(10px) scale(1.1);
}
  
.readMore i {
    transform: translateY(2px);
}